/* above Gaurav css */
/* --------- */
/*@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
*/

@font-face {
  font-family: "Bauhaus Harish wk";
  src: url("./font/BauhausRegular.ttf") format("truetype");
}

/* ritik changes */
/* input{ touch-action: none; } */
@media screen and (-webkit-min-device-pixel-ratio:0) {

  select:focus,
  textarea:focus,
  input:focus {
    font-size: 14px;
  }
}

/* ritik changes */
html,
body {
  touch-action: manipulation;
  /* overflow-x: hidden; */
  width: 100%;
  height: 100%;
}

select:focus,
textarea:focus,
input:focus {
  font-size: 14px;
}


body {
  margin: 0px;
  padding: 0px !important;
  font-family: 'Roboto';
  padding: 0 !important;
  /* padding-top: 0px !important; */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.ig-homepage {
  background-color: white;
}

.ig_main_container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: 400;
  vertical-align: baseline;
}


.signup-benefit-btn-img {
  width: 10px;
  height: 10px;
}

.ig-app-btn-img {
  width: 10px;
  height: 10px;
}

.ig-container {
  display: block;
  position: relative;
  width: 1160px;
  max-width: 100%;
  margin: 0 auto;
}

.flex-container {
  display: flex;
}


@media only screen and (min-width:1024px) and (max-width: 1180px) {
  .ig-search-widget-main-container {
    zoom: 85%;
  }
}

/* @media only screen and (max-width: 375px) {
  body {
    zoom: 85%;
  }
}

@media only screen and (max-width: 319px) {
  body {
    zoom: 75%;
  }
} */

.header-sticky {
  position: sticky;
  top: 0;
  z-index: 99;
}

.flex-row {
  display: flex;
}

.align-center {
  align-items: center;
}

.spc-btn {
  justify-content: flex-start;
  gap: 20px;
}

/* below Ritesh css */
h2.homepage-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #000000;
  margin: 0px;
  padding: 0px;
  /* margin-bottom: 48px; */
}


/* download IG big button */
.ig-app-and-signup {
  display: flex;
  padding-top: 35px;
  /* cursor: pointer; */
  /* padding-bottom: 65px; */
}

.ig-app-and-signup button {
  height: 109px;
  /* max-width: 540px; */
  /* max-width: 571px; */
  width: 100%;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.1px;
  padding: 42px 0 42px 42px;
  color: #000000;
  text-align: left;
}

.ig-app-and-signup .ig-app-btn {
  margin-right: 16px;
}

.ig-app-btn-img,
.signup-benefit-btn-img {
  float: right;
  padding-right: 54px;
  width: 31px;
  height: 57px;
}

.ig-app-and-signup img {
  width: calc(100% - 15px) !important;
}

.ig-app-and-signup button.active {
  background: rgba(2, 123, 255, 0.05);
  border: 1px solid #021A94;
  border-radius: 12px;
}

/* button-css */

.ig-button-container .ig-white-btn {
  /* height: 16px; */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #000000;
  padding: 16.5px 34px;
  background-color: #fff;
  width: fit-content;
  border-radius: 24px;
}

/* .ig-slider-additional-class .vms_carousel_previousdiv {
  left: unset;
  right: 10px;
  top: 10px;
  z-index: 999;
} */


.something-went-erong-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.something-went-erong-main p {
  color: #F24822;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
  letter-spacing: 0.14px;
  padding-top: 20px;
}

.something-went-erong-main h4 {
  color: var(--black-90, rgba(0, 0, 0, 0.90));
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
  letter-spacing: 0.14px;
  padding-top: 10px;
}

.something-went-erong-main .something-went-erong-button {
  max-width: 311px;
  width: 100%;
}

.something-went-erong-main .something-went-erong-main-img {
  width: 90px;
  mix-blend-mode: multiply;
}

.something-went-wrong-main-container .vms_modalpop_modalcontainer {
  /* width: 500px; */
  min-height: max-content;
  left: 50%;
  right: 0;
  /* margin: 0 auto; */
  top: 50%;
  transform: translate(-50%, -50%);
  bottom: unset;
  max-width: 588px;
  width: 90%;
}

.something-went-erong-button {
  border-radius: 4px 5px 5px 5px !important;
  background: #001B94 !important;
  color: #FFF !important;
  font-family: Roboto !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  /* 125% */
  letter-spacing: 0.16px;
  margin-top: 40px !important;
}

.something-went-wrong-main-container .vms_modalpop_modalcontainer .vms_modalpop_modalclose svg {
  color: #000000 !important;
}

@media only screen and (max-width: 600px) {
  .something-went-wrong-main-container .vms_modalpop_modalcontainer {
    /* max-width: 100%;
    left: 0;
    right: 0;
    margin: 20px; */
    /* height: fit-content; */
  }

  .something-went-wrong-main-container .vms_modalpop_content {
    padding-bottom: 15px;
  }
}

.filterrangeslider {
  width: 100%;
}

.filterrangeslider div.vms_accordion_closed.vms_accordion_container .vms_accordion_body {
  display: initial !important;
  visibility: hidden;
  height: 0px;
  padding: 0px 0px
}

.filterrangeslider div.vms_accordion_container .vms_accordion_body {
  display: block !important;
  height: auto;
}

.filterrangeslider div.vms_accordion_container .vms_accordion_body .vms_RangeSelector_Container {
  width: 100%;
}

.ig-app-and-signup button:first-child {
  background-color: rgb(2 123 255 / 5%);
  border: 1px #021A94 solid;
}

.ig-homepage .ig-search-widget-main-container.ig-container .search-hotels-btn-container button.vms_button {
  font-size: 16px;
  font-weight: 600;
  background: none 0% 0% / 200% 100% rgb(0, 27, 148);
  border-radius: 8px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.jp-mobile-recently-searched>li {
  padding: 0 0 24px 0;
  border-bottom: none;
}

.HDPbannersectionfix ol>li.vms_carousel_childitem.vms_carousel_childitem_active:last-child {
  width: 1260px;
}

.ig-app-and-signup button:last-child {
  margin: 0;
}

.ig-container div.vms_carousel_container {
  /* width: 1176px; */
  width: 1160px;
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .ig-container div.vms_carousel_container {
    width: 1000px;
  }
}

.vms_DateRangeCalendar_PrevIconContainer,
.vms_DateRangeCalendar_NextIconContainer {
  cursor: pointer;
}

/* .vms_DateRangeCalendar_dateItem {
  width: 30px;
  height: 30px;
  border: 2px #fff solid;
} */

/* td.vms_DateRangeCalendar_itemMainContainer:hover .vms_DateRangeCalendar_dateItem {
  border-radius: 50%;
  background: #f4f4f8 !important;
  color: #027bff;
  border: 2px #f4f4f8 solid;
}

td.vms_DateRangeCalendar_todayDate_Active .vms_DateRangeCalendar_dateItem {
  background: #f4f4f8;
  color: #027bff;
  border: 2px #f4f4f8 solid;

  border: 1px solid #027BFF;
  border-radius: 50%;
  background-color: #fff;
} */

.vms_DateRangeCalendar_PrevIconContainer,
.vms_DateRangeCalendar_NextIconContainer {
  height: 20px;
  /* margin-top: 4px; */

  display: grid;
  place-items: center;
}

.vms_DateRangeCalendar_NextIconContainer {
  margin-left: auto;
}

td.selected.between.vms_DateRangeCalendar_itemMainContainer .vms_DateRangeCalendar_dateItem {
  border: 0;
  position: relative;
}

td.selected.between.saturday.vms_DateRangeCalendar_itemMainContainer .vms_DateRangeCalendar_dateItem {
  border-radius: 0 30px 30px 0;
  background: #F4F4F8;
}

td.selected.between.sunday.vms_DateRangeCalendar_itemMainContainer .vms_DateRangeCalendar_dateItem {
  border-radius: 30px 0 0 30px;
  background: #F4F4F8;
}

.homepagehovervideorow {
  height: 93vh;
  width: 100% !important;
}

.homepagehovervideorow>video {
  object-fit: cover;
  border-radius: 4px 6px 0px 0px;
}

.video-container {
  position: relative;
  height: 100%;
}

/* .video-container::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(transparent, transparent, black);

} */

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 360px;
  object-fit: cover;
  background-position: center;
  z-index: -1;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #001B94;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.loader-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #001B94;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  animation: spin 1s linear infinite;
  margin-top: -10px;
  align-items: center;
}

@media (max-width: 768px) {

  /* Adjust the max-width as needed */
  .loader-spinner {
    margin-top: -6px;
    /* Margin for responsive screens */
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rc-slider-handle.rc-slider-handle-2 {
  /* width: 30px !important;
  height: 30px !important; */
  width: 24px !important;
  height: 24px !important;
  top: -1px;
  cursor: pointer !important;
  background-color: #FFFFFF;
  opacity: 1;
  left: unset;
  right: 0 !important;
  border: 1px solid #218AFE;
}

.rc-slider-handle.rc-slider-handle-1 {
  /* width: 30px !important;
  height: 30px !important; */
  width: 24px !important;
  height: 24px !important;
  top: -1px;
  left: 0px;
  cursor: pointer !important;
  background-color: #FFFFFF;
  border: 1px solid #218AFE;
  opacity: 1;
}


.no-result-found-image {
  text-align: center;
  margin: 0 auto;
}



.no-result-found-image img {
  height: 250px !important;
  width: auto !important;
}

.no-result-found-image h3 {
  font-size: 24px;
  font-weight: 500;
  font-family: 'Roboto';
}

@media screen and (max-width: 768px) {

  footer.footer,
  .header .headerv2 {
    display: none;
  }

  .loader {
    position: absolute;
    top: 40%;
    right: 44%;
  }

  .loader-container {
    background: #000000;
    height: -webkit-fill-available;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  .no-result-found-image img {
    height: 250px !important;
    /* max-width: 100%; */
    max-width: 90%;
    width: unset !important;
    aspect-ratio: 3 / 2;
    margin-top: 70px;
  }

  .no-result-found-image h3 {
    font-size: 20px;
  }


}

.ig_hdp_header_sticky {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2
}

.ig_hdp_bottom_bar_sticky {
  position: fixed;
}

.ig_hdp_tab_sticky_header {
  top: 55px !important;
}

.ig_hdp_tab_sticky {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #FFFFFF;
  z-index: 100;
}

.custom-button-group {
  position: absolute;
  /* top: 0; */
  top: 4px;
  left: 0;
  /* width: 100%; */
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  right: 17px;
  bottom: unset;
  z-index: 1;
}

.custom-button-group button {
  height: 24px;
  width: 24px;
  border-color: transparent;
  overflow: hidden;
  border-radius: 100%;
  background: #027BFF;
  cursor: pointer;

  display: grid;
  place-items: center;
}

.right-btn-disable,
.left-btn-disable {
  background-color: rgba(34, 34, 34, 0.10) !important;
  cursor: not-allowed !important;
}

.custom-button-group button img {
  height: auto !important;
  width: auto !important;
}

.react-multi-carousel-list {
  /* padding-top: 44px; */
}

.react-multi-carousel-dot button {
  background-color: #C4C4C4 !important;
  border-color: #C4C4C4 !important;
  height: 7px !important;
  width: 7px !important;
}

.react-multiple-carousel__arrow {
  z-index: 9 !important;
}

.react-multi-carousel-dot--active button {
  background-color: #fff !important;
  border-color: #fff !important;
  height: 8px !important;
  width: 8px !important;
}

.ig-hdp-similar-property-mobile-main-container .recommendation-card-main-conatiner {
  box-shadow: none;
}

.btn-disabled {
  user-select: none;
  pointer-events: none;
  color: #c3c3c3 !important;
}

/* powerd by start */


/* powerd by end */

/* disclaimer start */
.disclaimer-main-container {
  margin-top: 16px;
  box-shadow: 0px 2px 40px 0px #00000012;
  padding: 12px;
  background-color: #F6CC5B1A;
  border-radius: 12px;
}

.disclaimer-main-container .disclaimer-container-row {
  display: flex;
  align-items: flex-start;
  column-gap: 12px;
}

.disclaimer-logo {
  height: 16px;
  width: 16px;
  flex-shrink: 0;
  margin-top: 3px;
}

.disclaimer-logo img {
  height: 100%;
  width: 100%;
}

.disclaimer-text {
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
  ;
}

.disclaimer-main-container-mobile {
  /* width: 100%; */
  /* margin: 24px 16px; */
  box-sizing: border-box;
  padding: 12px 12px 16px;
}

.disclaimer-main-container-mobile .disclaimer-container-row {
  display: block;
}

.disclaimer-main-container-mobile .disclaimer-logo {
  margin: 0;
  margin-bottom: 8px;
}

.disclaimer-main-container-mobile .disclaimer-text {
  font-size: 14px;
  font-weight: 400;
}

.disclaimer-text-btn {
  margin-top: 10px;
  color: #218AFE;

  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-align: left;
}

/* disclaimer end */

.ig-home-container {
  min-height: calc(100vh - 160px);
}

.ig_footer_main_container {}

.ig_top_footer_container {
  background-color: #000090;
}

.ig_footer_main_container .ig_top_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #fff;

  padding: 43px 0 47px 0;
}

.ig_top_footer_left {
  display: flex;
}

.ig_top_footer_left .logo_img {
  width: 80px;
  display: inline-flex;
  margin-right: 50px;
}

.ig_middle_footer_container .ig_top_footer_left .logo_img {
  margin-right: 0;
}

.ig_top_footer_left .logo_img img {
  width: 100%;
}

.ig_top_footer_left .ig_top_footer_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  gap: 50px;
  cursor: pointer
}

.ig_top_footer_left .ig_top_footer_list li p {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.35px;
  text-align: left;
  color: #fff;
  text-decoration: none;
}

.ig_top_footer_right {
  display: flex;
  align-items: center;
}

.ig_top_footer_right .operated_text {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.35px;
  text-align: left;
  color: #cbcbcb;
  display: flex;
  align-items: center;
}

.ig_top_footer_right .operate_image {
  display: inline-flex;
  width: 70px;
  margin-left: 5px;
}

.ig_top_footer_right .operate_image img {
  width: 100%;
}

.ig_bottom_footer_container {
  padding: 11px 0;
  background-color: #000090;
}

.ig_bottom_footer_container .copywrite_text {
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.33px;
  color: #fff;
  text-transform: capitalize;
  font-family: "Roboto";
}

.ig_middle_footer_container {
  background-color: #000090;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ig_footer_main_container.ig_footer_main_mobile_container {
  padding: 0 15px;
  background-color: #000090;
}

.ig_footer_main_mobile_container .ig_top_footer {
  padding: 20px 0;
}

.ig_footer_main_mobile_container .ig_top_footer.footer_links {
  border-bottom: 1px solid #d2d2d2;
}

.ig_footer_main_mobile_container .ig_top_footer_left {
  width: 100%;
}

.ig_footer_main_mobile_container .ig_middle_footer_container .ig_top_footer_left,
.ig_footer_main_mobile_container .ig_middle_footer_container .ig_top_footer_right {
  display: unset;
  width: auto;
}

.ig_top_footer_right .operated_text strong {
  font-weight: bold;
}

.ig_footer_main_mobile_container .ig_top_footer_left .ig_top_footer_list {
  gap: 0;
  width: 100%;
}


@media screen and (min-width: 1023px) and (max-width: 1279px) {
  .ig_footer_main_container .ig_top_footer {
    padding: 43px 16px 47px;
  }
}

@media screen and (max-width: 1023px) {
  .ig_footer_main_container {
    /* display: none; */
  }

  .no-result-found-image {
    text-align: center;
    min-height: 65vh;
  }
}

@media screen and (max-width: 1024px) {
  .ig_middle_footer_container {
    justify-content: center;
  }
  .no-result-found-image img {
    height: 250px !important;
    max-width: 90%;
    width: unset !important;
    aspect-ratio: 3 / 2;
    margin-top: 70px;
  }
}

.skeleton_margin {
  margin-top: 90px;
}

/* .cmp-container{
  display: none;
} */

.ig-search-widget-main-container {
  /* position: sticky; */
  /* top: 75px;
  z-index: 2; */
}

a.ig_link {
  text-decoration: none;
  color: inherit;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px !important
  }
}


@media screen and (max-width: 767px) {
  .ig_Seo_homepage_content {
    padding: 16px !important;
  }

  @media screen and (min-width: 320px) {
    html {
      font-size: calc(100% + 1.1%);
    }
  }

  /* .ig_Seo_homepage_content ul li,
  .ig_Seo_homepage_content ol li {
    margin-left: 16px !important;
  } */

  input:focus {
    font-size: 16px !important
  }

  .seo-data-container {
    padding: 16px;
  }
}



.terms-and-conditions-container {
  padding: 35px 25px;
}

.terms-and-conditions-container .homepage-title {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 23.44px;
  text-align: left;
  color: #222222;
  margin-bottom: 25px;
}

.contact_us_container {
  background: #FDF8EF;
  border-radius: 10px;
  padding: 50px;
}

.contact_us_img_container {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 0px #0000000D;
  margin-right: 25px;
  padding: 25px 50px;
  border-radius: 30px;
  display: flex;
  width: 50%;
  align-items: center;
  flex-direction: column;
}

.contact_us_img_container .contact_us_img_div {
  width: 68px;
  height: 68px;
  border-radius: 100%;
  background: #DCDCF0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.contact_us_img_container .contact_us_img_div img {
  width: 24px;
  height: 24px;
}

.contact_us_img_container h2 {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  padding: 10px 0 15px;
  color: #222222;

}

.contact_us_img_container p {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;

}

.contact_us_contact_value {
  text-decoration: none;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  text-align: center;
  color: #000090;
  padding: 15px;
}


.ig_Seo_homepage_content h1,
.ig_Seo_homepage_content h2 {
  padding-top: 25px;
}

.ig_Seo_homepage_content h1,
.ig_Seo_homepage_content h2,
.ig_Seo_homepage_content h4 {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
}

.ig_Seo_homepage_content p {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  padding-top: 15px;
  color: #000000B2;
}


.ig_Seo_homepage_content ul li,
.ig_Seo_homepage_content ol li {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #000000B2;

}

.ig_Seo_homepage_content ul li b {
  font-weight: bold;
}

.ig_Seo_homepage_content .ig-main-list {
  padding-left: 25px;
  padding-top: 15px;
}

.ig_Seo_homepage_content .ig-main-list li {
  padding-bottom: 15px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #000000B2;

}

.hotels_near_me_list {
  padding-top: 15px;
  padding-left: 25px;
}

.hotels_near_me_list li {
  padding-bottom: 15px;
}

.ig_Seo_homepage_content ul li::marker {
  font-size: 8px;
}

.ig_Seo_homepage_content .ig-main-list li::marker {
  font-size: 10px;
}

.hotel_faq p {
  padding-top: 0;
  padding-bottom: 15px;
}

.ig_Seo_homepage_content a {
  color: #027BFF !important;
}

.seo_read_more {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #027BFF !important;
  cursor: pointer;
}



@media screen and (max-width: 767px) {
  .contact_us_container {
    flex-wrap: wrap;
    padding: 0 25px 25px;
    border-radius: 10px;
  }

  .contact_us_img_container {
    margin-right: 0;
    width: 100%;
    margin-top: 25px;
  }
 
}

.react-multi-carousel-dot-list {
  bottom: 10px !important;
}